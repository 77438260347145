<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
  saveGridState,
  loadGridState,
  connectToServerApi
} from "@/utils/agGridUtils";
import { getDefaultColDefs, getDefaultGridOptions } from '../../utils/aggridCommonConfigs.js';
import TagsRenderer from './TagsRenderer.vue';
import { useThemeStore } from "@/stores/themeStore";
import ResetColumnComponent from "../../components/common/aggrid/ResetColumnComponent.vue";
import Warehouses from '../../components/common/Warehouses.vue';
import { shortDateWithTimeTz } from "@/utils/dateUtils.ts";
import PalletFilters from "./PalletFilters.vue";
import { useRouter } from "vue-router";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const PALLET_SEARCH_URL = "/lps?expand=warehouse,inbound_order,bin";
const GRID_SAVE_KEY = "pallet_search_grid_state";
const PALLET_SEARCH_EXTERNAL_FILTERS_KEY = "pallet_search_external_filters";
const gridLoaded = ref(false);
const router = useRouter();

let gridApi;
let context = ref(null);

const onGridReady = (params) => {
	gridApi = params.api;
	context.value = {
		apiUrl: PALLET_SEARCH_URL,
		filterKey: PALLET_SEARCH_EXTERNAL_FILTERS_KEY,
		advancedFilterKeys: advancedFilterKeys
	};
	loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
	gridLoaded.value = true;
	gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
	saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = getDefaultColDefs();

const advancedFilterKeys = ["id", "number", "warehouse.nickname", "product.nickname", "lot", "expiry", "pallet_qty"];

const columnDefs = [
	{ field: "id", minWidth: 100, headerName: "ID", colId: "id", cellDataType: "text" },
	{ field: "number", minWidth: 250, headerName: "LPN", colId: "number", cellDataType: "text", width: "300",
		cellRenderer: function (params) {
      if (params.data.number) {
        return `<a href="/lps/${params.data.id}">${params.data.number}</a>`;
      } else {
        return '';
      }
    },
	},
	{ field: "id", minWidth: 100, headerName: "Internal ID", colId: "id", cellDataType: "text"},
	{ field: "product.sku", minWidth: 200, headerName: "SKU", colId: "product.sku", width: "300",
		cellRenderer: function (params) {
      if (params.data.product.sku) {
        return `<a href="/products/${params.data.product.id}">${params.data.product.sku}</a>`;
      } else {
        return '';
      }
    },
	},
	{ field: 'product.nickname', minWidth: 200, headerName: "Product", colId: "product.nickname", cellDataType: "text", width: "300" },
	{ field: "lot", minWidth: 120, headerName: "Lot", colId: "lot", cellDataType: "text" },
	{
		field: "expiry", minWidth: 210, headerName: "Expiry", colId: "expiry", cellDataType: "text", valueGetter: (params) => {
			return shortDateWithTimeTz(params.data.expiry);
		}
	},
	{ field: 'pallet_qty', minWidth: 50, headerName: "Qty on Pallet", colId: "pallet_qty", cellDataType: "text"},
	{ field: 'inbound_order.number', minWidth: 200, headerName: "Inbound", colId: "inbound_order.number", cellDataType: "text", width: "300",
		valueGetter: (params) => {
			return params.data.inbound_order?.number + ' / ' + params.data.inbound_order?.po_number;
		}
	},
	{ field: 'warehouse.nickname', minWidth: 200, headerName: "Current Location", colId: "warehouse.nickname", cellDataType: "text", width: "300",
		valueGetter: (params) => {
			return params.data.warehouse?.nickname + ' / ' + params.data.bin?.name;
		}
	},
	{
		field: "tags", headerName: "Tags", colId: "tags", filter: false, minWidth: 250, sortable: false,
		cellRenderer: TagsRenderer,
	}
];

const rowDoubleClicked = function (event) {
    router.push(`/pallet-search/${event.data.id}`);
};

const gridOptions = getDefaultGridOptions(columnDefs);
gridOptions.autoSizeStrategy = {
	type: "fitCellContents",
}
</script>

<template>
	<Warehouses :callBack="() => gridApi.onFilterChanged()" :parent-filter="PALLET_SEARCH_EXTERNAL_FILTERS_KEY" />
	
	<div class="d-flex flex-row-reverse flex-wrap">
		<ResetColumnComponent :grid-save-key="GRID_SAVE_KEY" :grid-api="gridApi" />
		<PalletFilters v-if="gridLoaded" :gridApi="gridApi" />
	</div>

	<div class="px-4">
		<AgGridVue style="width: 100%; height: 800px;" :class="themeStore.agTheme" :columnDefs="columnDefs"
			:defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context"
			@state-updated="onStateUpdated" @rowDoubleClicked="rowDoubleClicked">
		</AgGridVue>
	</div>
</template>

