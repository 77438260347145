<script setup>
import { onMounted } from "vue";
import { setPageTitle } from "../../utils/pageTitle";
import PalletSearch from "@/components/pallet-search/PalletSearch.vue";

onMounted(() => {
  setPageTitle("LP Search");
});

</script>

<template>
  <div id="ecomm-grid-wrapper">
    <PalletSearch/>
  </div>
</template>

<style scoped>
#ecomm-grid-wrapper {
  height: 100%;
}
</style>
