<script setup>
import { useUserStore } from '@/stores/user';
import { onMounted, computed, ref, watch } from 'vue';

const props = defineProps({
  gridApi: Object
});

const searchType = ref('number');
const searchQuery = ref('');
const clientId = ref(null);

const userStore = useUserStore();

onMounted(() => {
  const lpn = props.gridApi.getColumnFilterModel('number');
  const lot = props.gridApi.getColumnFilterModel('lot');
  const expiry = props.gridApi.getColumnFilterModel('expiry');

  if (lpn) {
    searchType.value = 'number';
    searchQuery.value = lpn.filter
  } else if (lot) {
    searchType.value = 'lot';
    searchQuery.value = lot.filter
  } else if (expiry) {
    searchType.value = 'expiry';
    searchQuery.value = expiry.filter
  }
})

const searchPlaceholder = computed(() => {
	switch (searchType.value) {
		case 'number':
			return 'Enter a full or partial LPN/SSCC and press ENTER';
		case 'lot':
			return 'Enter a full or partial lot code and press ENTER';
		case 'expiry':
			return 'Enter a date (Format: YYYY-MM-DD) and press ENTER. YYYY-MM will also work';
	}
});

const palletSearch = async () => {
  if (!searchQuery.value) {
    return;
  }

  await props.gridApi.setColumnFilterModel(searchType.value, {
    filterType: 'text',
    type: 'contains',
    filter: searchQuery.value,
  });

  props.gridApi.onFilterChanged();
}

watch(clientId, (newVal) => {
  palletSearch();
})

watch(searchType, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    palletSearch();
  }
})

</script>

<template>
  <div class="d-flex flex-grow-1 mx-4 pl-2 pt-2 align-center">
    <strong>Search</strong>
    <div style="width: 25px; text-align: center;margin-left: 1rem;">
      <v-progress-circular :value="5" color="primary" indeterminate
        v-if="false"></v-progress-circular>
      <v-icon v-else>mdi-magnify</v-icon>
    </div>
    <div  style="flex-grow: 1; padding-right: 1rem; padding-left: 1rem;">
      <v-text-field
        class="search-input"
        :placeholder="searchPlaceholder"
        hide-details
        v-model="searchQuery"
        v-on:keyup.enter="palletSearch"
        density="compact">
      </v-text-field>
    </div>
    <div class="d-flex justify-content-between">
      <v-btn-toggle
        v-model="searchType"
        class="mr-4"
        density="compact"
        mandatory
        variant="outlined"
      >
        <v-btn small value="number">
          LPN/SSCC
        </v-btn>
        <v-btn small value="lot">
          Lot Code
        </v-btn>
        <v-btn small value="expiry">
          Expiry
        </v-btn>
      </v-btn-toggle>

        <!-- check tenant -->
      <template v-if="userStore.user.tenant_id == '1'">
        <div class="d-flex align-center">
          <strong>Customer:</strong>
          <v-btn-toggle
            density="compact"
            variant="outlined"
            v-model="clientId"
            background-color="primary"
          >
            <v-btn small value="10006">
              Liquid Death
            </v-btn>
            <v-btn small value="10076">
              Sovereign Brands
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.search-input {
  min-width: 150px;
}
</style>